<!--
 * @Author: your name
 * @Date: 2021-05-29 12:07:33
 * @LastEditTime: 2021-06-01 10:51:24
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \hxb-companyd:\workplace\qy_wx_h5\src\views\invite\index.vue
-->
<template>
  <div class="invite-wrapper">
    <p class="title">复制手机号向该客户发送邀请</p>
    <div class="invite-img">
      <img src="/imgs/phone.png" alt />
    </div>
    <div class="footer-wrapper">
      <div class="footer btn" @click="copy">复制手机号，并邀请联系人</div>
    </div>
  </div>
</template>
<script>
import { useRoute } from "vue-router";
import useClipboard from "vue-clipboard3";
import { Toast } from "vant";
import { toAddcustomerPage } from "@/utils/weChatConfig";
export default {
  name: 'Invite',
  setup() {
    const route = useRoute();
    console.log("route", route);
    const phoneNumber = route.query.mobile;
    const { toClipboard } = useClipboard();
    const copy = async () => {
      try {
        await toClipboard(phoneNumber);
        toAddcustomerPage().then((res) => {
          console.log(res);
        });
      } catch (err) {
        console.log(err);
        Toast({
          message: err,
        });
      }
    };
    return {
      copy,
    };
  },
};
</script>
<style lang="less" scoped>
.invite-wrapper {
  padding: 0px 40px;
  box-sizing: border-box;
  .title {
    font-size: 40px;
    font-weight: bold;
    color: #333;
    text-align: center;
  }
  .invite-img {
    margin: 0 auto;
    width: 523px;
    margin-top: 47px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .footer-wrapper {
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 24px 40px;
    background: #fff;
    box-sizing: border-box;
    .footer {
      width: 100%;
      background: #1678ff;
      border-radius: 8px;
      height: 88px;
      text-align: center;
      line-height: 88px;
      font-size: 34px;
      color: #fff;
    }
  }
}
</style>